import {ApiInfo, apiInfoFunc} from '../apiInfo'
import {PaymentMethodEnum} from '@/@types/scheme'


export type UserAddress = {
    firstName: string
    lastName: string
    zipcode: string
    prefectureId: number|string
    address1: string, // 市区町村
    address2: string, // 番地など
    phoneNumber: string
}
/**
 * 注文の確認
 */
export type OrderConfirmation = {
    itemCode: string
    isSubscribeMailmagazine: boolean,
    shipAddress: UserAddress,
    billAddress: UserAddress
    paymentMethod: PaymentMethodEnum | null
    couponCode: string | undefined,
}

export type OrderConfirmationResult = {
    itemCode: string,
    itemName: string,
    price: number,
    couponDiscountPrice: number,
    couponCode: string,
    image: {
        url: string,
    },
    totalPrice: number,
    shipOnDate: string, // 2022-03-16
    shippingFee: number,
    expectedPoints: number
    handlingFee: number,
}

// APIのURLに対して、型を確定させる
const apiInfoOrderConfirmation: ApiInfo<OrderConfirmationResult, undefined, OrderConfirmation > = {
    method: 'POST',
    url: () => '/web_ec_v2/order_confirmation/',
    response: {} as OrderConfirmationResult,
    query: undefined,
    requestBody: {} as OrderConfirmation
}

export const usePostOrderConfirmation = apiInfoFunc.toUseRequest(apiInfoOrderConfirmation)
