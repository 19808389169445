import { css } from '@emotion/react'
import {HoverButton} from '@components/atoms/Button/HoverButton'
import React from 'react'
import { Space } from '@/components/atoms/Space'
import {fonts} from '@/styles/fonts'




const containerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '40px 0 0 0',
})
const titleStyle = css({
    fontFamily: 'Noto Sans JP',
    color: '{colors.text.primary}',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '10%',
})
const bodyStyle = css({
    fontFamily: 'Avenir Next',
    color: '{colors.text.primary}',
    fontSize: fonts.size.fontMobile16Pc18,
    lineHeight: '21.8px',
    letterSpacing: '3%',
    textAlign: 'center',
})

export const GmoAtobaraiTimeout = () => (
    <div css={containerStyle}>
        <h1 css={titleStyle}>タイムアウトされました</h1>
        <Space height={40} />

        <p css={bodyStyle}>
            GMO後払いの審査が完了せず
            <br />
            タイムアウトしました。
            <br />
            <br />
            恐れ入りますが、カスタマーサポートへ
            <br />
            お問い合わせください
            <br />
            <br />
            <HoverButton
                label="お問い合わせ"
                onClick={() => {
                    window.location.href =
                'https://typefood-support.zendesk.com/hc/ja/requests/new'
                }}
            />
        </p>
    </div>

)
