import React from 'react'

/**
 * スペースを開けるためだけのコンポーネント
 * @param width スペースをつくための高さの数値。デフォルトで単位はpx
 * @param height スペースをつくための幅の数値。デフォルトで単位はpx
 * @param unit 単位。デフォルトはpx
 * @constructor
 */
export const Space: React.FC<{
  width?: number
  height?: number
  unit?: 'px' | 'rem' | 'em' | '%'
}> = ({ width = 0, height = 0, unit = 'px' }) => (
    <div
        style={{
            display: width > 0 ? 'inline-block' : 'block',
            width: width ? `${width}${unit}` : 0,
            height: height ? `${height}${unit}` : 0,
        }}
    />
)
