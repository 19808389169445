import React from 'react'
import {css} from '@emotion/react'
import {CardElement} from '@stripe/react-stripe-js'
import {FormLabel} from '@components/atoms/FormLabel'

import {FieldMessage} from '@components/atoms/FieldMessage'
import {ReactComponent as Card5Brand} from '@assets/img/card_5brand.svg'
import {StripeError} from '@stripe/stripe-js'
import {CreditCardCscHelp} from './CreditCardCscHelp'
import {ulTagStyle} from '@/styles/global'
import {pushDataLayer} from '@/utils/analytics'

const containerStyle = css({
    position: 'relative',
})
const labelItemStyle = css({
    display: 'flex',
    padding: '0 0 20px',
    '@media (max-width: 768px)': {
        display: 'block',
        padding: '0 0 16px',
    },
})
const inputItemStyle = css({
    display: 'flex',
    padding: '0',
    '@media (max-width: 768px)': {
        display: 'block',
        padding: '0 0 16px',
    },
})
const bodyStyle = css({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})
const cardImg = css({
    maxWidth: '300px',
    width: '100%',
    '@media (max-width: 480px)': {
        maxWidth: '240px',
    },
})
const cardNumberStyle = css({
    height: '56px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    borderRadius: '4px',
    padding: '0 12px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})

/**
 * クレジットカードの入力欄
 * @param props
 * @constructor
 */
export const CreditCardForm: React.VFC<{
    stripeError?:StripeError
}> = (props) => {
    const { stripeError } = props
    return (
        <ul css={[ulTagStyle, containerStyle]}>
            <li css={labelItemStyle}>
                <FormLabel isRequired={false}>ご利用可能なカード</FormLabel>
                <div css={bodyStyle}>
                    <Card5Brand css={cardImg}/>
                </div>
            </li>
            <li css={inputItemStyle}>
                <FormLabel isRequired>カード番号</FormLabel>
                <div css={bodyStyle}>
                    <div>
                        <fieldset css={cardNumberStyle}>
                            <CardElement
                                options={{ hidePostalCode: true }}
                                onFocus={() => {
                                    pushDataLayer({
                                        event: 'input',
                                        label: 'inputCreditcard'
                                    })
                                }}
                            />
                        </fieldset>
                    </div>
                    {(stripeError) && (<FieldMessage>{stripeError.message}</FieldMessage>)}

                    <CreditCardCscHelp />
                </div>
            </li>
        </ul>
    )
}
