import { css } from '@emotion/react'
import React from 'react'
import { Space } from '@/components/atoms/Space'
import { Spinner } from '@/components/atoms/Spinner'
import {fonts} from '@/styles/fonts'


const containerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '40px 37.5px',
})
const titleStyle = css({
    fontFamily: 'Noto Sans JP',
    color: '{colors.text.primary}',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '10%',
})
const bodyStyle = css({
    fontFamily: 'Avenir Next',
    color: '{colors.text.primary}',
    fontSize: fonts.size.fontMobile16Pc18,
    lineHeight: '21.8px',
    letterSpacing: '3%',
    textAlign: 'center',
})

export const GmoAtobaraiExamination = () => (
    <div
        css={css({
            backgroundColor: 'white',
            width: '100%',
            height: '268px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        })}
    >
        <div css={containerStyle}>
            <h1 css={titleStyle}>GMO後払い審査中</h1>
            <Space height={40} />
            <Spinner />
            <Space height={24} />
            <p css={bodyStyle}>
                GMO後払いの審査中です。
                <br />
                ページを開いたままお待ちください。
                <br />
                <br />
                最大60秒ほどかかります
            </p>
        </div>
    </div>
)
