import React from 'react'
import {fonts} from '@/styles/fonts'
import {pTagStyle} from '@/styles/global'

type MessageType = 'error' | 'warning' | 'info'
const colorMap = {
    error: '#DC3251',
    warning: '#F09041',
    info: '#000000',
}

/**
 * フィールドのメッセージを表示するコンポーネント。デフォルトでエラーメッセージを表示する
 * @constructor
 */
export const FieldMessage: React.VFC<{
    children: React.ReactNode
    type?: MessageType
}> = (props) => {
    const {children, type = 'error'} = props
    const color = colorMap[type]

    return (
        <p css={[pTagStyle,{
            fontSize: fonts.size.font12,
            fontWeight: 'bold',
            color,
            margin: '2px 0 0',
        }]}>
            {' '}
            {children}
        </p>
    )
}
