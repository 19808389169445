import React, {useCallback, useMemo} from 'react'
import {css} from '@emotion/react'
import {FormLabel} from '@components/atoms/FormLabel'
import {FieldMessage} from '@components/atoms/FieldMessage'
import {FieldErrors, FieldPath, UseFormRegister} from 'react-hook-form'
import {Select, SelectOptionValue} from '@components/atoms/Select/Select'
import {bodyFormStyle} from '@/styles/global'
import {ItemVariant} from '@/network/api/item'
import {usePushDataLayerOnce} from '@/utils/analytics'


/**
 * 1つのSKUに複数商品が選択できる場合に、商品を選択するFormコンポーネント
 * @param props
 * @constructor
 */
export const VariantForm = <TFieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: {
    register: UseFormRegister<TFieldValues>,
    errors: FieldErrors,
    nameVariant:TFieldName
    variants: ItemVariant[]
}) => {
    const {register, errors, nameVariant, variants} = props
    // 選択肢
    const options: SelectOptionValue[] = useMemo(() =>
        variants.map(variant => ({value: variant.code, name: variant.name}))
    ,[variants])
    // イベント
    const pushDataLayerOnce = usePushDataLayerOnce()
    const onChange = useCallback(() => {
        pushDataLayerOnce({
            event: 'select',
            label: 'selectVariants'
        })
    }, [pushDataLayerOnce])

    if (variants.length === 0) {
        return null
    }

    return (
        <li>
            <FormLabel isRequired>商品</FormLabel>
            <div css={bodyFormStyle}>
                <div css={css({maxWidth:'300px'})}>
                    <Select<TFieldValues> register={register} name={nameVariant} options={options} onChange={onChange}/>
                    {(errors?.[nameVariant]) && (<FieldMessage >{errors?.[nameVariant].message}</FieldMessage>)}
                </div>
            </div>
        </li>
    )
}
