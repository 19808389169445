import {css, keyframes} from '@emotion/react'
import React from 'react'

const cycleAnim = keyframes({
    to: {
        transform: 'rotate(1turn)',
    },
})
const loadingStyle = css({
    width: '40px',
    aspectRatio: '1',
    borderRadius: '50%',
    border: '5px solid #D9D9D9',
    borderRightColor: '#2EB6AA',
    animation: `${cycleAnim} 1s infinite linear`,
})


export const Spinner = () => <div css={loadingStyle} />
