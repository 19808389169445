import {baseUrl} from '@/configure/env'

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE'
/**
 * エラーはこの形式で取扱う
 */
export type ErrResult = {
    code: string,
    message: string
}

export type NoContentResult = {
    status: 204
}
/**
 * ネストされたbody anyを許さないために定義
 */
export type NestedBody = Record<
                            string,
                            boolean|number|string|null|undefined
                            |Record<
                                string,
                                boolean|number|string|null|undefined
                                |Record<
                                    string,
                                    boolean|number|string|null|undefined>>>

/**
 * レスポンスのコンテンツタイプがapplication/jsonかどうかを確認する
 * @param response
 */
const isContentTypeJson = (response: Response): boolean => {
    const contentType = response.headers.get('content-type')
    return contentType ? contentType.indexOf('application/json') !== -1 : false
}

/**
 * methodとurlとbodyを投げたら、いい感じにリクエストしてくれる
 * リクエストに成功したらTResponseDataでPromiseを解決する。
 * リクエストして失敗したら、ErrResultでエラーを投げる。そうすることによって、swrがうまいことerrorとして拾ってくれる。
 * @param method
 * @param url
 * @param body
 */
export const fetcher = async <TResponseData extends object, TRequestBody = unknown>(
    method: HttpMethod,
    url: string,
    body?:TRequestBody
) => {
    const requestOption: RequestInit = {
        method,
        credentials: 'include',
        mode:'cors',
        headers:{
            'Content-Type': 'application/json',
            'Yukashikado-Store-Id': 'vitanote',
        },
        body: body ? JSON.stringify(body) : undefined,
    }

    const response = await fetch(baseUrl + url, requestOption)
    try {

        if(response.status === 204){
            return { status: 204 } as TResponseData
        }
        // json形式の正常なレスポンス or エラー
        if(isContentTypeJson(response)) {
            const responseJson = await response.json()
            if(!response.ok){
                throw responseJson as ErrResult
            }
            return responseJson as TResponseData
        }
        // 500などのサーバー外のエラー(jsonではない)
        const responseText = await response.text()
        throw { code: response.statusText, message: responseText }
    }catch (e: ErrResult | any) { // anyをつかわざるを得ない
        if(e?.code){
            throw e
        }else{
            // 本当に想定していないエラー
            throw { code: 'unknown', message: e }
        }
    }
}


