import {useMemo} from 'react'
import {rootDiv} from '@/utils/htmlElements'

/**
 * HTMLから取得するデータセット
 */
export type HtmlDataset = {
    // 商品コード
    itemCode: string
    // 1つのSKUないで商品の選択を行えるようにするかどうか(フレーバー選択など)
    isVisibleVariant?:boolean
    // ご注文内容の確認 商品欄につける説明。改行こみ(\n)のテキスト。
    itemText?: string
    // 商品を買うとそれが自動でセレクト便に入る機能がある。それを使うかどうか
    // trueだと、ご注文内容の確認 単価と合計にテキストを追加する
    isAddRecurring?:boolean
    // ご注文内容の確認 発送予定日欄につける説明。
    shipOnDateText?:string
    // ご注文内容の確認 ご注文完了前にご確認ください欄につける説明。
    confirmBeforePurchaseText:string
    // 注文フォームでクーポン情報の入力フォームを表示するかどうか(trueで表示する)
    // 注文確認モーダルでクーポン情報を表示するかどうか(trueで表示する)
    isVisibleCoupon?:boolean
    // ダオコードを入力するか
    isVisibleDaoCode?:boolean
}
/**
 * htmlにあるdata-item-codeを取り出す
 */
const getItemCode = ():string => {
    if(rootDiv?.dataset?.itemCode === undefined){
        throw Error('data-item-codeがありません')
    }

    return rootDiv?.dataset?.itemCode
}

/**
 * htmlにあるdata-is-visible-variantを取り出す
 */
const getIsVisibleVariant = ():boolean => {
    if(rootDiv?.dataset?.isVisibleVariant === undefined){
        console.error('data-is-visible-variantがありません')
    }

    return rootDiv?.dataset?.isVisibleVariant === 'true'
}

/**
 * htmlにあるdata-item-textを取り出す
 */
const getItemText = ():string|undefined => {
    if(rootDiv?.dataset?.itemText === undefined){
        console.error('data-item-textがありません。\r\n必要ない場合は""をセットしてください。')
    }

    return rootDiv?.dataset?.itemText && rootDiv?.dataset?.itemText.replace('\\n','\n')
}
/**
 * htmlにあるdata-is-add-recurringを取り出す
 */
const getIsAddRecurring = ():boolean => {
    if(!rootDiv?.dataset?.isAddRecurring){
        console.error('data-is-add-recurringがありません')
    }
    if(rootDiv?.dataset?.isAddRecurring !== 'true' && rootDiv?.dataset?.isAddRecurring !== 'false'){
        console.error('data-is-add-recurringは、true もしくは falseを使ってください')
    }

    return rootDiv?.dataset?.isAddRecurring === 'true'
}

/**
 * htmlにあるdata-ship-on-date-textを取り出す
 */
const getShipOnDateText = ():string|undefined => {
    if(rootDiv?.dataset?.shipOnDateText === undefined){
        console.error('data-ship-on-date-textがありません。\r\n必要ない場合は""をセットしてください。')
    }
    return rootDiv?.dataset?.shipOnDateText && rootDiv?.dataset?.shipOnDateText.replace('\\n','\n')
}

/**
 * htmlにあるdata-confirm-before-purchase-textを取り出す
 */
const getConfirmBeforePurchaseText = ():string => {
    const confirmBeforePurchaseText = rootDiv?.dataset?.confirmBeforePurchaseText
    if(confirmBeforePurchaseText === undefined){
        throw Error('data-confirm-before-purchase-textがありません。\r\n必要ない場合は""をセットしてください。')
    }

    return confirmBeforePurchaseText.replace('\\n','\n')
}

/**
 * htmlにあるdata-is-visible-couponを取り出す
 */
const getIsVisibleCoupon = ():boolean => {
    if(rootDiv?.dataset?.isVisibleCoupon === undefined){
        console.info('data-is-visible-couponがありません')
    }

    return rootDiv?.dataset?.isVisibleCoupon === 'true'
}

const getIsVisibleDaoCode = ():boolean => {
    if(rootDiv?.dataset?.isVisibleDaocode === undefined){
        console.info('data-is-visible-daocodeがありません')
    }

    return rootDiv?.dataset?.isVisibleDaocode === 'true'
}


/**
 * htmlからデータセットを取り出しておく
 */
export const useHtmlDataset = ():HtmlDataset => {

    // ご購入確認画面の商品とともに説明に追加するテキスト
    const htmlDataset: HtmlDataset = useMemo(() => {
        try {
            return  {
                itemCode: getItemCode(),
                itemText: getItemText(),
                isVisibleVariant: getIsVisibleVariant(),
                isAddRecurring: getIsAddRecurring(),
                shipOnDateText: getShipOnDateText(),
                confirmBeforePurchaseText: getConfirmBeforePurchaseText(),
                isVisibleCoupon: getIsVisibleCoupon(),
                isVisibleDaoCode: getIsVisibleDaoCode()
            }
        }catch (e){
            // eslint-disable-next-line no-alert
            alert(e)
            throw e
        }

    }, [])

    return htmlDataset
}
