import React, { useState } from 'react'
import { css } from '@emotion/react'

import { fonts } from '../../../styles/fonts'
import { ReactComponent as CreditCardCvc } from '../../../assets/img/credit_card_cvc.svg'
import { ReactComponent as CreditCardCvcAmex } from '../../../assets/img/credit_card_cvc_amex.svg'
import { ReactComponent as HelpIcon } from '../../../assets/img/ico_help.svg'
import Modal from '../Modal'
import {buttonTagStyle, pTagStyle} from '@/styles/global'

const containerStyles = css({
    marginTop: '8px',
})
const headTtl = css({
    textAlign: 'center',
    fontWeight: '600',
    fontSize: fonts.size.fontMobile18Pc22,
    margin: '0 0 20px',
})
const description = css({
    textAlign: 'center',
    fontSize: fonts.size.fontMobile12Pc14,
})
const cardType = css({
    fontSize: '11px',
    margin: '4px 0 0',
})

export const CreditCardCscHelp: React.VFC = () => {
    const [isOpenCvcHelp, setIsOpenCvcHelp] = useState<boolean>(false)

    return (
        <div css={containerStyles}>
            <button
                css={[buttonTagStyle, css({
                    border: 'transparent',
                    background: 'transparent',
                    color: '#2EB6AA',
                    cursor: 'pointer',
                })]}
                onClick={() => setIsOpenCvcHelp(true)}
                type="button"
            >
                <span
                    css={css({
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        fontSize: fonts.size.font12,
                    })}
                >
                    CVCとは
                    <HelpIcon />
                </span>
            </button>

            <Modal
                isOpen={isOpenCvcHelp}
                close={() => {
                    setIsOpenCvcHelp(false)
                }}
                maxWidth="400px"
            >
                <p css={[pTagStyle, headTtl]}>CVCとは</p>
                <div css={description}>
                    クレジットカードのセキュリティー番号です。
                    <br />
                    カード会社により表示されている箇所/桁数が異なります。
                </div>
                <div
                    css={css({
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: '30px',
                    })}
                >
                    <div
                        css={css({
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '50%',
                            padding: '0 5px',
                        })}
                    >
                        <CreditCardCvc
                            css={css({ width: '100%', height: 'auto' })}
                        />
                        <p css={[pTagStyle, cardType]}>Visa / Master / JCB</p>
                    </div>
                    <div
                        css={css({
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '50%',
                            padding: '0 5px',
                        })}
                    >
                        <CreditCardCvcAmex
                            css={css({ width: '100%', height: 'auto' })}
                        />
                        <p css={[pTagStyle, cardType]}>アメリカン・エキスプレス</p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
