import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {FieldMessage} from '@components/atoms/FieldMessage'
import {AddressForm} from '@components/organisms/AddressForm'
import {HoverButton} from '@components/atoms/Button/HoverButton'
import {useFirebaseAuthEmailStatus} from '@components/pages/PurchaseFormPage/useFirebaseAuthEmailStatus'
import {usePasswordIcon} from '@components/pages/PurchaseFormPage/usePasswordIcon'
import {useCreateCreditCard} from '@components/atoms/CreditCardForm/useCreateCreditCard'
import {Space} from '@components/atoms/Layout/Space'
import {VariantForm} from '@components/organisms/VariantForm'
import {RequiredExplainText} from '@components/pages/PurchaseFormPage/RequiredExplainText'
import {PurchaseLayout} from '../../organisms/PurchaseLayout'
import {CenterBlackTitle} from '../../atoms/CenterBlackTitle'
import {FormLabel} from '../../atoms/FormLabel'
import {CheckboxText} from '../../atoms/CheckBox/CheckboxText'
import {fonts} from '@/styles/fonts'
import {CheckboxButton} from '../../atoms/CheckBox/CheckboxButton'
import {CreditCardForm} from '../../atoms/CreditCardForm/CreditCardForm'
import {usePostOrderConfirmation, UserAddress} from '@/network/api/orderConfirmation'
import {
    CustomConfirmationFromHtmlDataset,
    OrderConfirmationInfo,
    OrderConfirmationModal
} from '../../organisms/OrderConfirmationModal'
import {ErrResult} from '@/network/fetcher'
import {bodyFormStyle, inputTagStyle, pTagStyle, sectionTagAfterStyle, ulTagStyle} from '@/styles/global'
import {isValidPassword} from '@/utils/validator/passwordValidator'
import {Item} from '@/network/api/item'
import {pushDataLayer, usePushDataLayerOnce} from '@/utils/analytics'
import {PaymentMethodEnum} from '@/@types/scheme'

type FieldValue = {
    email: string
    password: string
    variantItemCode?: string
    paymentMethod: PaymentMethodEnum
    isSubscribeMailmagazine: boolean
    billAddress: UserAddress
    shipAddress?: UserAddress // isUseAnotherAddressForShipがtrueだと取れる
    couponCode: string | undefined
    mzdaoCode: string | null
}

const textFieldStyle = css({
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    boxSizing: 'border-box',
    padding: '12px',
    fontSize: '16px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})
const listFormStyle = css({
    li: {
        display: 'flex',
        padding: '0 0 20px',
    },
    '@media (max-width: 768px)': {
        li: {
            display: 'block',
            padding: '0 0 16px',
        },
    },
})
const mailAddressMsgStyle = css({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '6px 0 12px',
})
const txtLinkStyle = css({
    color: '#2eb6aa',
    margin: '0 0 0 auto',
})

const radioButtonStyle = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    color: '#333',
    padding: '0 0 8px 0',
})

const radioLabelStyle = css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
})

const radioInputStyle = css({
    appearance: 'none',
    margin: '0 8px 0 0',
    width: '16px',
    height: '16px',
    border: '1px solid #EAEBED',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:checked::before': {
        content: '""',
        width: '10px',
        height: '10px',
        backgroundColor: '#2EB6AA',
        borderRadius: '50%',
    },
    '&:checked': {
        border: '1px solid #2EB6AA',
    }
})

const paymentMethodBoxStyle = css({
    border: '4px solid #E3E3E3',
    padding: '16px',
    marginTop: '19px',
})
const paymentMethodBoxTitleStyle = css({
    fontSize: fonts.size.fontMobile14Pc16,
    fontWeight: 'bold',
    padding: '0 0 12px 0',
    margin: '0',
})
const paymentMethodBoxBodyStyle = css({
    fontSize: fonts.size.fontMobile12Pc14,
    margin: '0',
})

/**
 * itemを購入するか、もしくは、itemにvariantがあれば、variantを選択して購入するためのフォーム
 * @param props
 * @constructor
 */
export const PurchaseFormPage: React.VFC<{
    item: Item | undefined
    htmlDataset: CustomConfirmationFromHtmlDataset
    setPage: React.Dispatch<React.SetStateAction<Page>>
    setErrResult: React.Dispatch<React.SetStateAction<ErrResult | undefined>>
}> = (props) => {
    const { setErrResult, setPage, item, htmlDataset } = props
    // フォーム全体
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        control,
        formState: { errors },
    } = useForm<FieldValue>({
        defaultValues: { isSubscribeMailmagazine: true, paymentMethod: PaymentMethodEnum.Creditcard },
    })
    const pushDataLayerOnce = usePushDataLayerOnce()
    // バリアント使うかどうか
    const shouldSelectVariant =
        htmlDataset.isVisibleVariant && item && item.variants.length > 0
    // メールフォーム関連
    const [mailAddressMsg, setMailAddressMsg] = useState<
        React.ReactNode | undefined
    >()
    const emailStatus = useFirebaseAuthEmailStatus()
    useEffect(() => {
        setError('email', {})
        if (emailStatus.authMethodStatus === 'newUser') {
            setMailAddressMsg(undefined)
        }
        if (emailStatus.authMethodStatus === 'authBySns')
            setMailAddressMsg(
                <div css={mailAddressMsgStyle}>
                    <p css={[pTagStyle, css({ color: '#DC3251' })]}>
                        このメールアドレスはパスワードの設定が必要です。
                    </p>
                    <a
                        css={txtLinkStyle}
                        href="https://id.vitanote.jp/password_reset_email"
                    >
                        {' '}
                        パスワードの再設定はこちら
                    </a>
                </div>
            )
        if (emailStatus.authMethodStatus === 'invalidEmail')
            setError('email', {
                type: 'manual',
                message: '有効なメールアドレスをご入力ください。',
            })

        // authMethodStatusの状態のみ監視
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailStatus.authMethodStatus])
    // パスワードフォーム関連
    const { PasswordIcon, passwordInputType } = usePasswordIcon()
    // クレジットカードフォーム関連
    const { createCreditCard, stripeError, creditCard } = useCreateCreditCard()
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<PaymentMethodEnum>(PaymentMethodEnum.Creditcard)

    // 請求先と届け先が違う
    const [isUseAnotherAddressForShip, setIsUseAnotherAddressForShip] =
        useState<boolean>(false)
    // 注文の確認
    const [orderConfirmationInfo, setOrderConfirmationInfo] = useState<
        OrderConfirmationInfo | undefined
    >(undefined)
    const [isLockedClickOrderConfirmation, setIsLockedClickOrderConfirmation] =
        useState<boolean>(false) // 二段送信防止
    const postOrderConfirmation = usePostOrderConfirmation()
    const onSubmitConfirm: SubmitHandler<FieldValue> = async (data) => {
        setIsLockedClickOrderConfirmation(true)
        setTimeout(() => {
            setIsLockedClickOrderConfirmation(false)
        }, 3000)
        const {
            password,
            email,
            shipAddress,
            variantItemCode,
            paymentMethod,
            couponCode,
            mzdaoCode,
            ...postData
        } = JSON.parse(JSON.stringify(data)) as FieldValue
        // アカウントあるのにログイン失敗するのはここで弾く
        const loginStatus = await emailStatus.checkLoginBeforeOrderConfirm(
            email,
            password
        )
        if (loginStatus === 'loginFailure') {
            setError('password', {
                type: 'missPassword',
                message: 'パスワードが間違っています。',
            })
            return
        }

        // 支払い情報を設定する
        if (paymentMethod === PaymentMethodEnum.Creditcard) {
            const creditCardResult = await createCreditCard()
            console.error('createCreditCard is invalid')
            if (!creditCardResult) {
                setError('paymentMethod', {
                    type: 'stripeError',
                    message: 'クレジットカード情報をご確認ください',
                })
                return
            }
        }else if(paymentMethod !== PaymentMethodEnum.Atobarai){
            console.error('paymentMethod is invalid')
        }

        // 商品がなければリクエストできない
        if (!item) {
            console.error('item is invalid')
            return
        }
        // variantsに配列があるのに、variantが選ばれていなければ、リクエストできない
        if (shouldSelectVariant && !variantItemCode) {
            console.error('variantItemCode is invalid')
            return
        }
        const itemCode =
            shouldSelectVariant && variantItemCode ? variantItemCode : item.code

        if (isUseAnotherAddressForShip && shipAddress) {
            await postOrderConfirmation.request({
                body: {
                    ...postData,
                    shipAddress,
                    itemCode,
                    paymentMethod,
                    couponCode,
                },
                query: undefined,
            })
        } else {
            await postOrderConfirmation.request({
                body: {
                    ...postData,
                    paymentMethod,
                    couponCode,
                    shipAddress: postData.billAddress,
                    itemCode,
                },
                query: undefined,
            })
        }
    }
    useEffect(() => {
        if (
            postOrderConfirmation.data &&
            !postOrderConfirmation.loading &&
            postOrderConfirmation.lastRequestedData
        ) {
            setOrderConfirmationInfo({
                ...getValues(),
                ...postOrderConfirmation.data,
                userIdToken: emailStatus.userIdToken,
                creditCard: creditCard || null,
                shipAddress:
                    postOrderConfirmation.lastRequestedData.body.shipAddress, // リクエストしたものが正しいのでココを参照している。
            })
        }
        if (postOrderConfirmation.error && !postOrderConfirmation.loading) {
            setErrResult(postOrderConfirmation.error)
        }
        // リクエスト完了時のみ監視
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postOrderConfirmation.loading])

    return (
        <PurchaseLayout>
            <form onSubmit={handleSubmit(onSubmitConfirm)}>
                <div css={css({ display: 'flex', flexDirection: 'column' })}>
                    <RequiredExplainText />
                    {shouldSelectVariant && (
                        <section>
                            <CenterBlackTitle>ご注文内容</CenterBlackTitle>
                            <ul css={[ulTagStyle, listFormStyle]}>
                                <VariantForm<FieldValue>
                                    register={register}
                                    errors={errors}
                                    nameVariant="variantItemCode"
                                    variants={item.variants}
                                />
                            </ul>
                            <Space height="35px" />
                        </section>
                    )}
                    <section>
                        <CenterBlackTitle>お客様情報</CenterBlackTitle>

                        <ul css={[ulTagStyle, listFormStyle]}>
                            <AddressForm<FieldValue>
                                control={control}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                setError={setError}
                                nameFirstName="billAddress.firstName"
                                nameLastName="billAddress.lastName"
                                nameZipCode="billAddress.zipcode"
                                namePrefectureId="billAddress.prefectureId"
                                nameAddress1="billAddress.address1"
                                nameAddress2="billAddress.address2"
                                namePhoneNumber="billAddress.phoneNumber"
                            />
                            <li>
                                <FormLabel isRequired>メールアドレス</FormLabel>
                                <div css={bodyFormStyle}>
                                    <input
                                        placeholder="vitanote@sample.co.jp"
                                        type="text"
                                        css={[inputTagStyle, textFieldStyle]}
                                        {...register('email', {
                                            validate: () =>
                                                emailStatus.authMethodStatus ===
                                                'invalidEmail'
                                                    ? '有効なメールアドレスをご入力ください。'
                                                    : true,
                                            required: '入力してください。',
                                            onChange: () => {
                                                pushDataLayerOnce({
                                                    event: 'input',
                                                    label: 'inputMailAddress',
                                                })
                                            },
                                            onBlur: async (event: {
                                                target: { value: string }
                                            }) => {
                                                await emailStatus.checkEmailAuthStatus(
                                                    event.target.value
                                                )
                                            },
                                        })}
                                    />

                                    {errors?.email && (
                                        <FieldMessage>
                                            {errors.email.message}
                                        </FieldMessage>
                                    )}
                                    <FieldMessage type="warning">
                                        ※既にYUKASHIKADO
                                        IDをお持ちの方は、ご登録のメールアドレス、パスワードをご入力ください。
                                        <br />
                                        ※上記の内容でアカウントの仮登録を行います。定期便を利用する方の情報を正しくご入力ください
                                    </FieldMessage>
                                    {mailAddressMsg && (
                                        <FieldMessage type="info">
                                            {mailAddressMsg}
                                        </FieldMessage>
                                    )}
                                    <div css={css({ padding: '12px 0 0' })}>
                                        <Controller
                                            name="isSubscribeMailmagazine"
                                            control={control}
                                            render={({ field }) => (
                                                <CheckboxText
                                                    label="VitaNoteからのお知らせを受け取る"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <br />
                            </li>
                            <li>
                                <FormLabel isRequired>パスワード</FormLabel>
                                <div css={bodyFormStyle}>
                                    <div
                                        css={css({
                                            width: '100%',
                                            position: 'relative',
                                        })}
                                    >
                                        <input
                                            placeholder="半角英数字6文字以上"
                                            type={passwordInputType}
                                            css={[
                                                inputTagStyle,
                                                textFieldStyle,
                                            ]}
                                            {...register('password', {
                                                required: '入力してください。',
                                                onChange: () => {
                                                    pushDataLayerOnce({
                                                        event: 'input',
                                                        label: 'inputPassword',
                                                    })
                                                },
                                                onBlur: () => {
                                                    setError('password', {})
                                                },
                                                minLength: {
                                                    message:
                                                        '半角英数字、6文字以上で入力してください。',
                                                    value: 6,
                                                },
                                                validate: (value) =>
                                                    isValidPassword(value)
                                                        ? true
                                                        : '半角英数字、6文字以上で入力してください。',
                                            })}
                                        />
                                        {PasswordIcon}
                                        {errors.password && (
                                            <div>
                                                <FieldMessage>
                                                    <>
                                                        {
                                                            errors.password
                                                                .message
                                                        }
                                                        {errors.password
                                                            .type ===
                                                            'missPassword' && (
                                                            <>
                                                                <p css={pTagStyle}>登録済みのメールアドレスに対応する正しいパスワードを入力してください。</p>
                                                                <a css={txtLinkStyle} href="https://id.vitanote.jp/password_reset_email">
                                                                    パスワードをお忘れの方
                                                                </a>
                                                            </>
                                                        )}
                                                    </>
                                                </FieldMessage>
                                            </div>
                                        )}
                                    </div>
                                    <p
                                        css={css({
                                            fontSize: fonts.size.font12,
                                            color: '#666',
                                            margin: '2px 0 0',
                                        })}
                                    >
                                        半角英数字6文字以上で入力してください
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <div
                            css={css({
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            })}
                        >
                            <CheckboxButton
                                label="上記と別の住所へお届けする"
                                setIsChecked={setIsUseAnotherAddressForShip}
                                isChecked={isUseAnotherAddressForShip}
                            />
                        </div>
                    </section>
                    {/* お届け先が違う場合 */}
                    {isUseAnotherAddressForShip && (
                        <section css={sectionTagAfterStyle}>
                            <CenterBlackTitle>お届け先</CenterBlackTitle>
                            <ul css={ulTagStyle} className="list_form">
                                <AddressForm<FieldValue>
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    setError={setError}
                                    nameFirstName="shipAddress.firstName"
                                    nameLastName="shipAddress.lastName"
                                    nameZipCode="shipAddress.zipcode"
                                    namePrefectureId="shipAddress.prefectureId"
                                    nameAddress1="shipAddress.address1"
                                    nameAddress2="shipAddress.address2"
                                    namePhoneNumber="shipAddress.phoneNumber"
                                />
                            </ul>
                        </section>
                    )}
                    <section css={sectionTagAfterStyle}>
                        <CenterBlackTitle>お支払い情報</CenterBlackTitle>
                        <div css={radioButtonStyle}>
                            <label css={radioLabelStyle} htmlFor="credit-card">
                                <input
                                    type="radio"
                                    id="credit-card"
                                    value={PaymentMethodEnum.Creditcard}
                                    css={radioInputStyle}
                                    {...register('paymentMethod', {
                                        required:
                                            'お支払い情報を選択してください',
                                        onChange: (event) => {
                                            setSelectedPaymentMethod(event.target.value as PaymentMethodEnum)
                                            pushDataLayerOnce({
                                                event: 'select',
                                                label: 'selectPaymentMethod',
                                            })
                                        }
                                    })}
                                />
                                クレジットカード決済
                            </label>
                            <label css={radioLabelStyle} htmlFor="gmo-postpay">
                                <input
                                    type="radio"
                                    id="gmo-postpay"
                                    css={radioInputStyle}
                                    value={PaymentMethodEnum.Atobarai}
                                    {...register('paymentMethod', {
                                        required:
                                            'お支払い情報を選択してください',
                                        onChange: (event) => {
                                            setSelectedPaymentMethod(event.target.value as PaymentMethodEnum)
                                            pushDataLayerOnce({
                                                event: 'select',
                                                label: 'selectPaymentMethod',
                                            })
                                        }
                                    })}
                                />
                                GMO後払い（※手数料 +230円）
                            </label>
                            {selectedPaymentMethod ===
                                PaymentMethodEnum.Creditcard && (
                                <div css={paymentMethodBoxStyle}>
                                    <p css={paymentMethodBoxTitleStyle}>
                                        クレジットカードで支払う
                                    </p>
                                    <p css={paymentMethodBoxBodyStyle}>
                                        すべての取引は暗号化され、安全に扱われます。
                                    </p>
                                    <CreditCardForm stripeError={stripeError} />
                                </div>
                            )}
                            {selectedPaymentMethod ===
                                PaymentMethodEnum.Atobarai && (
                                <div css={paymentMethodBoxStyle}>
                                    <p css={paymentMethodBoxTitleStyle}>
                                        GMO後払い注意事項
                                    </p>
                                    <p css={paymentMethodBoxBodyStyle}>
                                        GMO後払いとはお客様の手元に商品が到着した後に代金をお支払い頂く決済方法です。
                                        <br/>
                                        全国のコンビニエンスストア、銀行でお支払いいただけます。
                                        <br/>
                                        <br/>
                                        ■お支払い期限
                                        請求書発行から14日以内にお支払いください。お支払い期限は請求書にも記載しております。
                                        <br/>
                                        お支払い期限を一定期間過ぎてもお支払いの確認がとれない場合、 ご請求金額に回収事務手数料297円（税込）が加算されます。（最大3回、合計891円）
                                        <br/>
                                        <br/>

                                        ■ご注意 後払い手数料：230円
                                        <br/>
                                        GMO後払いは
                                        <a css={txtLinkStyle} href="https://www.gmo-ps.com/" target="_blank"
                                            rel="noreferrer">GMOペイメントサービス株式会社</a>が提供する決済サービスです。
                                        <br/>
                                        当社はGMOペイメントサービス株式会社に対しサービスの範囲内で個人情報を提供し、代金債権を譲渡します。
                                        <br/>
                                        GMO後払いサービスの<a css={txtLinkStyle}
                                            href="https://www.gmo-ps.com/customer_2/" target="_blank"
                                            rel="noreferrer">注意事項</a>
                                        および、<a css={txtLinkStyle}
                                            href="https://www.gmo-ps.com/customer/privacy_operation/"
                                            target="_blank" rel="noreferrer">プライバシーポリシー</a>
                                        に同意のうえ、GMO後払いサービスをご利用ください。
                                        <br/>
                                        ・ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。
                                        <br/>
                                        ・ご利用にあたり審査がございます。審査結果によっては「GMO後払い」をご利用いただけない場合がございますので、その場合には別のお支払方法へ変更をお願いします。
                                        <br/>
                                        ・ご利用限度額はGMO後払い累計で、55,000円（税込）です。
                                    </p>
                                </div>
                            )}
                        </div>
                        {errors?.paymentMethod && (
                            <FieldMessage>
                                {errors.paymentMethod.message}
                            </FieldMessage>
                        )}
                    </section>

                    {htmlDataset.isVisibleCoupon && (
                        <section css={sectionTagAfterStyle}>
                            <CenterBlackTitle>クーポン情報</CenterBlackTitle>
                            <ul css={[ulTagStyle, listFormStyle]}>
                                <li>
                                    <FormLabel isRequired={false}>
                                        クーポンコード
                                    </FormLabel>
                                    <div
                                        css={[
                                            bodyFormStyle,
                                            css({ maxWidth: '300px' }),
                                        ]}
                                    >
                                        <input
                                            placeholder="クーポンコード"
                                            type="text"
                                            css={[
                                                inputTagStyle,
                                                textFieldStyle,
                                            ]}
                                            {...register('couponCode', {
                                                required: false,
                                                onChange: () => {
                                                    pushDataLayerOnce({
                                                        event: 'input',
                                                        label: 'inputCouponCode',
                                                    })
                                                },
                                                minLength: {
                                                    message:
                                                        '半角数字8文字 または 半角英数字10文字で入力してください。',
                                                    value: 8,
                                                },
                                            })}
                                        />
                                        {errors?.couponCode && (
                                            <FieldMessage>
                                                {errors.couponCode.message}
                                            </FieldMessage>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </section>
                    )}

                    {htmlDataset.isVisibleDaoCode && (
                        <section css={sectionTagAfterStyle}>
                            <CenterBlackTitle>
                                スタートアップ支援コード
                            </CenterBlackTitle>
                            <ul css={[ulTagStyle, listFormStyle]}>
                                <li>
                                    <FormLabel isRequired>
                                        スタートアップ支援コード
                                    </FormLabel>
                                    <div
                                        css={[
                                            bodyFormStyle,
                                            css({ maxWidth: '350px' }),
                                        ]}
                                    >
                                        <input
                                            placeholder="半角英数字12文字で入力してください"
                                            type="text"
                                            css={[
                                                inputTagStyle,
                                                textFieldStyle,
                                            ]}
                                            {...register('mzdaoCode', {
                                                required:
                                                    'スタートアップ支援コードを入力してください',
                                                onChange: () => {
                                                    pushDataLayerOnce({
                                                        event: 'input',
                                                        label: 'inputMzdaoCode',
                                                    })
                                                },
                                                minLength: {
                                                    message:
                                                        '半角英数字12文字で入力してください。',
                                                    value: 12,
                                                },
                                                maxLength: {
                                                    message:
                                                        '半角英数字12文字で入力してください。',
                                                    value: 12,
                                                },
                                            })}
                                        />
                                        {errors?.mzdaoCode && (
                                            <FieldMessage>
                                                {errors.mzdaoCode.message}
                                            </FieldMessage>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </section>
                    )}
                </div>

                <HoverButton
                    type="submit"
                    label="ご注文内容を確認する"
                    loading={
                        postOrderConfirmation.loading ||
                        isLockedClickOrderConfirmation
                    }
                    onClick={() => {
                        pushDataLayer({
                            event: 'click',
                            label: 'clickOrderConfirmationButton',
                        })
                    }}
                />
            </form>
            <OrderConfirmationModal
                close={() => {
                    setOrderConfirmationInfo(undefined)
                }}
                orderConfirmationInfo={orderConfirmationInfo}
                setPage={setPage}
                htmlDataset={htmlDataset}
                setErrResult={setErrResult}
            />
        </PurchaseLayout>
    )
}
